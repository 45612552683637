.numbers {
  background-color: white;
  color: #061633bf;
  display: flex;
  padding: 4rem 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.numbers .facts {
  display: flex;
  flex-direction: row;
  width: 600px;
  max-width: 80vw;
  justify-content: space-between;
  margin: 0 auto;
  gap: 2rem;
}

.fact {
  text-align: center;
}

.fact .number {
  font-size: 2.5rem;
  font-weight: 600;
}
.rupee {
  font-size: 2rem;
}
.sub-title {
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 1200px;
  line-height: 2rem;
  text-align: justify;
}
#pricing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0b2345;
  color: white;
  padding: 4rem 2rem;
}

.plans {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem 0;
  flex-wrap: wrap;
  align-items: stretch;
}

.plan {
  border-radius: 19px;
  width: 17rem;
  padding: 2rem 2rem 1rem;
  background-color: white;
  color: black;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: space-between;
  border: 0.5vmin solid #123971;
  overflow: hidden;
  color: black;
}

.content {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.plan-desc {
  font-size: 1rem;
  color: #777777;
}

.pricing {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  /* padding: 1rem; */
}

.duration {
  color: #777777;
  font-size: 1.25rem;
}

.price {
  font-weight: 800;
  font-size: 2.5rem;
  margin: 0;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

}
.feature p{
  width: 10.3rem;
}
.stat-title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 4rem;
  text-align: center;
}
.desc {
  font-size: 1.2rem;
}
@media only screen and (max-width: 1286px) {
  .stat-title {
    font-size: 2.8rem;
  }
}
@media only screen and (max-width: 1024px) {
}
@media (max-width: 930px) {
  .stat-title {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .numbers .facts {
    .fact {
      .number {
        font-size: 1.6rem;
      }
      .desc {
        font-size: 0.8rem;
      }
    }
  }
  .stat-title {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 616px) {
  .stat-title {
    font-size: 1.7rem;
  }
}
@media only screen and (max-width: 480px) {
  .stat-title {
    font-size: 1.5rem;
  }
}
@media (max-width: 420px) {
  .stat-title {
    font-size: 1.2rem;
  }
}
@media (max-width: 391px) {
}
@media (max-width: 350px) {
  #numb {
    font-size: 0.9rem;
  }
  .stat-title {
    font-size: 1rem;
  }
}
@media (max-width: 310px) {
  .stat-title {
    font-size: 0.8rem;
  }
}
