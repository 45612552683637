/* Scroller Start */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f2f2f2;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: white;
  white-space: nowrap;
  position: relative;
}

/* logo size adjustment */
.logos-slide img {
  width: auto;
  max-height: 50px;
  margin: 0 20px;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}
/* Blur Sides */
/* 
.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
} */
/* Hover Animation Pause */
/* .logos:hover .logos-slide {
  animation-play-state: paused;
} */

.logos-slide {
  display: inline-block;
  animation: 15s slide infinite linear;
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
}

/* Scroller End */
.about-us {
  text-align: center;
  background-color: #061633;
  color: white;
  padding: 3.5rem 5rem;
}

.heading {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.most-hyped-hackathon {
  text-align: center;
  padding: 3rem 5rem;
}

.stats {
  display: flex;
  justify-content: center;
  /* justify-content: space-between; */
  /* max-width: 25rem; */
  gap: 8rem;
  margin: 1rem auto;
  margin-top: 2.5rem;
}

@media (max-width: 550px) {
  .stats {
    gap: 7rem;
  }
}

@media (max-width: 500px) {
  .stats {
    gap: 6rem;
  }
}

@media (max-width: 460px) {
  .stats {
    gap: 5rem;
  }
}

@media (max-width: 420px) {
  .stats {
    gap: 4rem;
    /* flex-direction: column;
    gap: 3rem;  */
  }
}

@media (max-width: 410px) {
  .stats {
    gap: 3.5rem;
  }

  .stats-num {
    font-size: 0.8rem;
  }
}

.stats-num {
  font-size: 2rem;
}
.stats-info {
  font-size: 1.5rem;
}

#one {
  text-align: justify;
  font-size: 1.2rem;
  margin-top: 1.5rem;
}

.home-sponsors {
  padding: 2rem 0;
  background-color: white;
  display: flex;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.home-sponsor-card {
  padding: 1rem 0;
  /* height: 5rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-sponsor-card img {
  height: 2.5rem;
  background-size: cover;
}

.home-sponsors {
  padding: 2rem 0;
  background-color: white;
  overflow: hidden;
}

.slider {
  display: flex;
  animation: scroll 60s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100%));
  }
}

.home-sponsor-card {
  padding: 1rem;
  display: flex;
  align-items: center;
}

.home-sponsor-card img {
  height: 2.5rem;
  width: auto;
}

@media only screen and (max-width: 1286px) {
  .logos-slide img {
    height: 40px;
  }
}

@media only screen and (max-width: 1024px) {
  .home-sponsors {
    gap: 2rem;
  }

  .home-sponsor-card img {
    height: 2rem;
    width: auto;
  }
  .logos-slide img {
    height: 30px;
  }
}

@media (max-width: 930px) {
}

@media only screen and (max-width: 768px) {
  .home-sponsor-card img {
    height: 1.7rem;
    width: auto;
  }
  .logos-slide img {
    height: 25px;
  }
}

@media screen and (max-width: 700px) {
  .about-us {
    padding: 3rem 2rem;
  }

  .most-hyped-hackathon {
    padding: 3rem 2rem;
  }

  .home-sponsor-card img {
    height: 1.4rem;
    width: auto;
  }
}

@media only screen and (max-width: 616px) {
  .home-sponsor-card img {
    height: 1.2rem;
    width: auto;
  }
}

@media only screen and (max-width: 480px) {
  .home-sponsors {
    gap: 1.5rem;
  }

  .home-sponsor-card img {
    height: 1rem;
    width: auto;
  }

  .heading {
    font-size: 1.5rem;
  }
  .logos {
    padding: 2rem;
  }
}

@media (max-width: 420px) {
  .home-sponsors {
    gap: 1rem;
  }

  .home-sponsor-card img {
    height: 0.9rem;
    width: auto;
  }

  .home-sponsor-card {
    padding: 0.5rem 0;
  }

  #one {
    font-size: 0.8rem;
  }

  .stats p {
    font-size: 0.8rem;
  }
}

@media (max-width: 391px) {
}

@media screen and (max-width: 360px) {
  .home-sponsors {
    gap: 0.5rem;
  }

  .home-sponsor-card img {
    height: 0.8rem;
  }
}

@media (max-width: 350px) {
  .heading {
    font-size: 1.3rem;
  }
}

@media (max-width: 320px) {
  .home-sponsors {
    gap: 0.7rem;
  }

  .home-sponsor-card img {
    height: 0.7rem;
  }

  .home-sponsor-card {
    padding: 0;
  }
}
