.past-sponsorss {
  display: flex;
  color: white;
  align-items: center;
  padding: 4rem 1rem 2rem 1rem;
  flex-direction: column;
  background-color: #0b2345;
}
.title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 4rem;
}
.past-title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 4rem;
  padding-right: 3rem;
  display: flex;
  flex-wrap: wrap;
}
.past {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.past-desc {
  font-size: 1.5rem;
  line-height: 2.05rem;
  width: 40%;
  min-width: 17rem;
  max-width: 80vw;
  text-align: justify;
}
.pSponsors {
  display: flex;
  gap: 3rem 2rem;
  margin: 5rem 0;
  justify-content: center;
  flex-wrap: wrap;
}

.pSponsors .p {
  width: calc(25% - 5rem);
  min-width: 5rem;
  height: 10rem;
  max-width: 80vw;
  display: flex;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.pSponsors .p img {
  width: 80%;
  object-fit: cover;
}
@media only screen and (max-width: 1286px) {
}
@media only screen and (max-width: 1024px) {
}
@media (max-width: 930px) {
}
@media only screen and (max-width: 768px) {
  .pSponsors .p {
    height: 5rem;
  }
  .past {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
  }

  .past-title {
    font-size: 1.15rem;
    font-weight: 1000;
    padding-right: 0;
    margin-bottom: 1rem;
    line-height: 1.2;
    align-self: center;
    margin-left: 10px;
  }

  .past-desc {
    font-size: 0.9rem;
    line-height: 1.2;
    margin-right: 5px;
  }
}
@media only screen and (max-width: 616px) {
}
@media only screen and (max-width: 480px) {
}
@media (max-width: 420px) {
  .past-desc {
    display: none;
  }
}
@media (max-width: 391px) {
}
@media (max-width: 350px) {
}
@media (max-width: 320px) {
}
