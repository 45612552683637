.hero-bg {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
  z-index: 3;
  position: relative;
}

.overlayT {
  position: absolute;
  top: 0;
  opacity: 0.9;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url("../../assets/images/bgTwo.jpg");
  background-size: cover;
  background-position: 50% 25%;
}

html {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

.Home {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  text-align: center;
  color: white;
}

.gradient-text {
  background: -webkit-linear-gradient(#061633, #347fc2);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.f {
  color: #777777;
  font-weight: 500;
  margin-bottom: 1rem;
}

.feature {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.check {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #7777778f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
}

.check i {
  scale: 0.75;
}

.plan .primary {
  width: 100%;
  border-radius: 0.5rem;
}
.Home .title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 4rem;
}
@media only screen and (max-width: 1286px) {
}
@media only screen and (max-width: 1024px) {
  .Home .title {
    font-size: 2.5rem;
  }
}
@media (max-width: 930px) {
}
@media only screen and (max-width: 768px) {
  .Home .title {
    font-size: 2.2rem;
  }
}
@media only screen and (max-width: 616px) {
  .Home .title {
    font-size: 2rem;
    line-height: 3rem;
  }
}
@media only screen and (max-width: 480px) {
  .Home .title {
    font-size: 1.6rem;
  }
}
@media (max-width: 420px) {
  .Home .title {
    font-size: 1.3rem;
    line-height: 2.5rem;
  }
}
@media (max-width: 391px) {
}
@media (max-width: 350px) {
  .Home .title {
    font-size: 1.1rem;
  }
  #presented {
    font-size: 0.9rem;
  }
}
@media (max-width: 320px) {
  .Home .title {
    font-size: 1rem;
    line-height: 2rem;
  }
  #presented {
    font-size: 0.7rem;
  }
}
