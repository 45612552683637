.contact {
  background-color: white;
  color: #061633bf;
  display: flex;
  padding: 4rem 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  .pocs {
    display: flex;
    max-width: 800px;
    margin: 0 auto;
    width: calc(100% - 6rem);
    border-radius: 3rem;
    padding: 4rem 3rem;
    background-color: #f3f7fb;
    color: #59657a;
    gap: 1rem 0;
    justify-content: space-between;
    flex-wrap: wrap;
    box-shadow: 0px 2px 10px 0px #004585;
  }
}

.poc {
  max-width: calc(80vw - 4rem);
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.partition {
  width: 1px;
  background-color: #d6dbe2;
}

.poc .details {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  margin-left: -1rem;
}

.poc p {
  max-width: 15rem;
}

.poc .details .h {
  width: 10rem;
}

.poc .details .detail {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: 1.5rem;
}

.avatar {
  width: 3rem;
  height: 3rem;
  object-fit: fill;
  overflow: hidden;
  border-radius: 999px;
}

.club {
  font-size: 0.75rem;
  color: black;
}

.link {
  display: flex;
  gap: 1rem;
  color: #0b2345;
  align-items: center;
}

.link i {
  color: #004585;
  scale: 1.1;
}

.poc2 .link i {
  color: #004585;
  scale: 1.5;
}
.poc2 {
  padding-right: 4rem;
  /* margin-right: 10rem; */
  /* margin-top: 2rem; */
}

.poc1 {
  box-sizing: border-box;
  padding-left: 3rem;
}

.poc2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* gap: 4rem; */
}

@media only screen and (max-width: 1286px) {
}
@media only screen and (max-width: 1024px) {
}
@media (max-width: 930px) {
}
@media only screen and (max-width: 830px) {
  .pocs {
    padding: 4rem 1rem;
    width: calc(100% - 1rem);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
  }

  .poc1 {
    padding: 0;
    justify-content: center;
    align-items: left;
    text-align: left;
  }

  .details {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .poc2 {
    margin: 1rem 0 0;
    display: flex;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }

  .poc2 a {
    padding: 0 1rem;
  }

  .poc2 .link {
    flex-direction: column;
    gap: 2rem; /* Added gap between links */
  }

  .poc2 .link p {
    height: 2rem;
    line-height: 1rem;
    overflow: hidden;
    display: none;
  }

  .link i {
    color: #004585;
    scale: 1;
  }

  .poc2 .link i {
    color: #004585;
    scale: 1.8;
  }

  /* Hide links with IDs #a2 and #a4 */
  #a2,
  #a4 {
    display: none;
  }
}

@media only screen and (max-width: 616px) {
}
@media only screen and (max-width: 480px) {
  .pocs {
    margin: 0rem;
  }
  #contact {
    font-size: 2rem;
  }
  .poc p {
    font-size: 1.1rem;
  }
  .link p {
    font-size: 0.8rem;
  }
  #a1,
  #a3 {
    font-size: 0.8rem;
  }
}


@media (max-width: 900px) {
  .pocs {
    display: flex;
    flex-direction: column;
  }

  .partition {
    display: none;
  }

  .poc2 {
    display: flex;
    flex-direction: row;
  }



  .pocs {
    padding: 4rem 1rem;
    width: calc(100% - 2rem);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
  }

  .poc1 {
    padding: 0;
    justify-content: center;
    align-items: left;
    text-align: left;
  }

  .details {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .poc2 {
    margin: 1rem 0 0;
    display: flex;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }

  .poc2 a {
    padding: 0 1rem;
  }

  .poc2 .link {
    flex-direction: column;
    gap: 2rem;
  }

  .poc2 .link p {
    height: 2rem;
    line-height: 1rem;
    overflow: hidden;
    display: none;
  }

  .link i {
    color: #004585;
    scale: 1;
  }

  .poc2 .link i {
    color: #004585;
    scale: 1.8;
  }

  #a2,
  #a4 {
    display: none;
  }
}


@media (max-width: 480px) {
  .poc1 {
    gap: 1.3rem;
  }

}
@media (max-width: 330px) {
  #contact {
    font-size: 1.5rem;
    
  }
  .contact {
    gap: 1rem;
  }
  .poc1 {
    gap: 1rem;
  }

}

@media (max-width: 480px) {
  .pocs,
  #poc-container {
    margin: 0;
    padding: 2rem;
    width: calc(100% - 1rem);
    /* padding: 0; */
  }
}
