* {
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}
#root {
  max-width: 100vw;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0.7rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #080041;
}
::-webkit-scrollbar-thumb:active {
  background: #080041;
}
::-webkit-scrollbar-track {
  background: black;
}
::-webkit-scrollbar-thumb {
  background: #0c082e;
}
::-webkit-scrollbar-corner {
  background: #000000;
}
