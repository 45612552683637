/*
  .Timeline {
  background-color: #113157;
  padding: 1rem;
}
.timeline-title {
  padding-top: 2rem;
  color: white;
  font-weight: 741;
  text-align: center;
  font-size: 3rem;
}
.timeline-title p {
  font-size: 1.5rem;
  padding: 1rem 0;
  font-weight: 100;
  width: 100%;
}
#timeLine {
  text-align: center;
}
.timeline-content {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*.airplane img {
  width: 5rem;
}
.airplane {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 1rem;
}
.dotted-line {
  border-left: 0.3rem dotted white;
  height: 30rem;
}
.box {
  width: 17.5rem;
  height: 7.5rem;
  border-radius: 0.75rem;
  background-color: #d9d9d9;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
}
/*
.box left{
  margin-bottom: 8rem;
}
.box {
  margin-top: 8rem;
}
.hiddenOne {
  display: none;
}
.timeline-content ul {
  padding: 3.125rem 0; /* Converted from 50px to rem 
}

.timeline-content ul li {
  list-style-type: none;
  position: relative;
  width: 0.375rem; /* Converted from 6px to rem 
  margin: 0 auto;
  padding-top: 3.125rem; /* Converted from 50px to rem 
  padding-left: 0.25rem; /* Converted from 4px to rem 
  background: #fff;
}
.timeline-content ul .left{
  margin-bottom: 8rem;
}
.timeline-content ul .right{
  margin-bottom: 8rem;
}
.timeline-content ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 1.875rem; /* Converted from 30px to rem 
  height: 1.875rem; /* Converted from 30px to rem 
  border-radius: 50%;
  background: inherit;
  z-index: 1;
}

.timeline-content ul li div::before {
  content: "";
  position: absolute;
  bottom: 0.4375rem; /* Converted from 7px to rem 
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline-content ul li:nth-child(odd) div {
  left: 2.8125rem; /* Converted from 45px to rem 
}

.timeline-content ul li:nth-child(odd) div::before {
  left: -0.9375rem; /* Converted from -15px to rem 
  border-width: 0.5rem 1rem 0.5rem 0; /* Converted from 8px 16px 8px 0 to rem 
  border-color: transparent transparent transparent;
}

.timeline-content ul li:nth-child(even) div {
  left: -27.4375rem; /* Converted from -439px to rem 
}

.timeline-content ul li:nth-child(even) div::before {
  right: -0.9375rem; /* Converted from -15px to rem 
  border-width: 0.5rem 0 0.5rem 1rem; /* Converted from 8px 0 8px 16px to rem 
  border-color: transparent transparent transparent;
}

time {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem; /* Converted from 8px to rem 
}

.timeline-clippy ul li::after {
  width: 2.5rem; /* Converted from 40px to rem 
  height: 2.5rem; /* Converted from 40px to rem 
  border-radius: 0;
}

.timeline-rhombus ul li::after {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.timeline-rhombus ul li div::before {
  bottom: 0.75rem; /* Converted from 12px to rem 
}


.timeline-star ul li::after {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.timeline-heptagon ul li::after {
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
}

.timeline-infinite ul li::after {
  animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.25);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}

@media only screen and (max-width: 1286px) {
}
@media only screen and (max-width: 1024px) {
}
@media (max-width: 930px) {
}
@media only screen and (max-width: 768px) {
}
@media screen and (max-width: 766px) {
  .hiddenOne {
    display: flex;
  }
  .content_right {
    display: none;
  }
  .content_left .box {
    margin: 1rem;
  }
  .hide {
    display: none;
  }
  .timeline-title p {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 616px) {
}
@media only screen and (max-width: 480px) {
}
@media (max-width: 420px) {
}
@media (max-width: 391px) {
}
@media (max-width: 350px) {
}
@media (max-width: 320px) {
}

@media (max-width: 430px) {
  .box {
    width: 14rem; /* Decrease box width 
    height: 6rem; /* Decrease box height 
    font-size: 0.9rem; /* Decrease font size 
    margin-bottom: 1rem; /* Add margin bottom to create spacing 
  }

  .content_left .box {
    margin-top: 3rem; /* Increase margin between boxes on the left 
  }

  .content_right .box {
    margin-top: 3rem; /* Increase margin between boxes on the right 
  }
}


@media (max-width: 370px) {
  .box {
    width: 12rem; /* Decrease box width further 
    height: 5rem; /* Decrease box height further 
    font-size: 0.8rem; /* Decrease font size further 
    margin-bottom: 1.5rem; /* Add more margin bottom for increased spacing 
  }

  .content_left .box {
    margin-top: 4rem; /* Increase margin between boxes on the left further 
  }

  .content_right .box {
    margin-top: 4rem; /* Increase margin between boxes on the right further 
  }
}
*/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Timeline {
  background-color: #113157;
  padding: 1rem;
}
.timeline-title {
  padding-top: 2rem;
  color: white;
  font-weight: 741;
  text-align: center;
  font-size: 3rem;
}
.timeline-title p {
  font-size: 1.5rem;
  padding: 1rem 0;
  font-weight: 100;
  width: 100%;
}
#timeLine {
  text-align: center;
}
.timeline ul {
  padding: 3.125rem 0;
}

.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 0.375rem;
  margin: 0 auto;
  padding-top: 3.125rem;
  background: #fff;
}

.timeline ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  background: inherit;
  z-index: 1;
}

.box {
  width: 18rem;
  height: 7.5rem;
  border-radius: 0.75rem;
  background-color: #d9d9d9;
  margin-bottom: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.box .head {
  /* align-self: center; */
  text-align: center;
  font-weight: 600;
  font-size: 1.13rem;
  /* height: 1.3rem; */
  /* width: 9.7rem; */
  width: 100%;
  margin-bottom: 0.2rem;
  /* margin: 0rem 0rem 2rem 0.5rem; */
}
.box .content {
  width: 100%;
  font-size: 0.8rem;
  /* width: 16rem; */
  text-align: center;
  /* justify-content: flex-start; */
  margin-bottom: 0.5rem;
}
.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 25rem;
  padding: 0;
}

.timeline ul li .box::before {
  content: "";
  position: absolute;
  bottom: 0.4375rem;
  width: 0;
  height: 0;
  border-style: solid;
}
/* 
.timeline ul li:nth-child(odd) div {
  left: 2.8125rem;
} */

.timeline ul li:nth-child(odd) .box {
  left: 2.8125rem;
}

.timeline ul li:nth-child(odd) div::before {
  left: -0.9375rem;
  border-width: 0.5rem 1rem 0.5rem 0;
  border-color: transparent #d9d9d9 transparent transparent;
}

.timeline ul li:nth-child(even) .box {
  left: -27.4375rem;
}

.timeline ul li:nth-child(even) div::before {
  right: -0.9375rem;
  border-width: 0.5rem 0 0.5rem 1rem;
  border-color: transparent transparent transparent #d9d9d9;
}

time {
  display: none;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.timeline ul li::after {
  transition: background 0.5s ease-in-out;
}

/* Circle Color change */

.timeline ul li.in-view::after {
  background: #3b87cb;
}

.timeline ul li div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.timeline ul li:nth-child(odd) div {
  transform: translate3d(12.5rem, 0, 0);
}

.timeline ul li:nth-child(even) div {
  transform: translate3d(-12.5rem, 0, 0);
}

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 56.25rem) {
  .timeline ul li div {
    width: 17.5rem;
    display: grid;
    text-align: center;
  }

  .box .head {
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    width: 17.5rem;
    padding: 0;
    height: 1.1rem;
    margin-bottom: 0;
  }
  .box .content {
    font-size: 0.75rem;
    width: 17.5rem;
    text-align: center;
    margin-left: 2rem;
  }
  .timeline ul li:nth-child(even) .box {
    left: 3rem;
  }
  .timeline ul li:nth-child(even) div::before {
    left: -0.9375rem;
    border-width: 0.5rem 1rem 0.5rem 0;
    border-color: transparent #d9d9d9 transparent transparent;
  }
}

@media screen and (max-width: 37.5rem) {
  .timeline ul li {
    margin-left: 1.25rem;
  }
  .timeline ul li div {
    width: calc(100vw - 6.6875rem);
    display: grid;
    align-items: center;
  }
  .box .head {
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    width: calc(100vw - 6.6875rem);
    padding: 0;
    height: 1.1rem;
    margin-bottom: 0;
  }
  .box .content {
    font-size: 0.72rem;
    width: calc(100vw - 8.6875rem);
    text-align: center;
    justify-self: center;
    margin-left: 0;
  }
  .timeline ul li:nth-child(even) div {
    left: 0.3125rem;
  }
  .timeline ul li:nth-child(odd) .box .content {
    justify-content: center;
  }
  .timeline ul li:nth-child(even) .box .content {
    justify-content: center;
  }
  .timeline ul li:nth-child(even) div::before {
    left: -0.9375rem;
    border-width: 0.5rem 1rem 0.5rem 0;
    border-color: transparent #d9d9d9 transparent transparent;
  }
}

/* EXTRA/CLIP PATH STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.timeline-clippy ul li::after {
  width: 40px;
  height: 40px;
  border-radius: 0;
}

.timeline-rhombus ul li::after {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.timeline-rhombus ul li div::before {
  bottom: 12px;
}

.timeline-star ul li::after {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.timeline-heptagon ul li::after {
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
}

.timeline-infinite ul li::after {
  animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.25);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}
