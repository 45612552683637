.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
  background-color: transparent;
}
.nav-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
}
.nav-1 img {
  height: 1.5rem;
}
.primary {
  padding: 1rem 2rem;
  background-color: #347fc2;
  color: white;
  width: 10rem;
  display: flex;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 2rem;
  align-items: center;
  cursor: pointer;
}

#homeRegiterbutton {
  font-size: 1.1rem;
  transition: ease-in;
}

#homeRegiterbutton:hover {
  background-color: #00437d;
}

.nav-2 {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px;
  border-radius: 10px;
  width: 100vw;
}
.nav-2 a {
  font-size: 1.15rem;
  opacity: 0.8;
  cursor: pointer;
}

.nav-2 a:hover {
  opacity: 1;
  font-weight: bold;
}

.nav-2 .active {
  opacity: 1;
  font-weight: bold;
}
a {
  color: white;
  opacity: 0.8;
  text-decoration: none;
}

.ham {
  background-color: transparent;
  color: white;
  outline: none;
  border: none;
  padding: 1rem;
  cursor: pointer;
  display: none;
}
@media only screen and (max-width: 1286px) {
}
@media only screen and (max-width: 1024px) {
}
@media (max-width: 930px) {
}
@media only screen and (max-width: 768px) {
  .nav-1 img {
    height: 0.75rem;
    margin: 0 1rem;
    width: auto;
  }
  .navbar {
    gap: 0;
  }
  .nav-1 .primary {
    display: none;
  }
  .ham {
    display: block;
  }
  .nav-2.active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-color: #00091a;
    display: flex;
    justify-content: flex-end;
    gap: 22px;
    z-index: -1;
    padding-top: 300px;
    margin-left: 0;
    padding-left: 39px;
    padding-bottom: 30px;
  }

  /* .nav-2.active {
    width: 100%;
    background-color: #00091a;
    display: flex;
    padding: 1rem;
    margin-left: 0;
    a {
      font-size: 0.95rem;
    }
  } */
  .nav-1 {
    padding: 0.5rem;
  }
  .nav-2 {
    display: none;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1.5rem;
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }
}
@media only screen and (max-width: 616px) {
}
@media only screen and (max-width: 480px) {
}
@media (max-width: 420px) {
}
@media (max-width: 391px) {
}
@media (max-width: 350px) {
}
@media (max-width: 320px) {
}
