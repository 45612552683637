footer {
  background-color: #061734;
  color: white;
  padding: 0 2rem 2rem;
  width: 100%;
  margin: 0 auto;
}

footer .content {
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
  display: flex;
  padding: 12rem 0 1rem;
  flex-direction: column;
  justify-content: center;
}

footer .program {
  position: absolute;
  top: 0%;
  left: 50%;
  padding: 3rem;
  background-color: white;
  width: calc(100% - 6rem);
  max-width: 80vw;
  display: flex;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  color: #061633;
  border-radius: 1.25rem;
}

footer .program .subscribe {
  display: flex;
  border-radius: 1.5rem;
  background-color: #f3f7fb;
  align-items: center;
  gap: 2rem;
  padding: 0 0 0 2rem;
  color: black;
}

.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

footer .items {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

footer .items img {
  height: 1rem;
}

footer .links {
  display: flex;
  gap: 2rem;
}

footer .links i {
  scale: 1.5;
}

.logoOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
  /* height: 4rem;
  width: 10rem; */
}

#logoFooter {
  height: 4rem;
}

.links a:hover {
  opacity: 1;
  font-weight: bold;
}

.links .active {
  opacity: 1;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  footer {
    padding: 0 0.5rem;
    font-size: 0.85rem;
    .program {
      padding: 2rem;
      width: fit-content;
      /* min-width: fit-content; */
      max-width: 80vw;
      /* width: calc(100% - 6.5rem); */
    }
    .program .subscribe {
      gap: 1rem;
    }
    .items {
      padding: 0 0.5rem;
      max-width: calc(100% - 1rem);
    }
    .items img {
      height: 0.6rem;
    }
    .links {
      gap: 1rem;
    }
  }
}

footer .email {
  font-size: 0.9rem;
  border-color: transparent;
  border-radius: 2rem;
  background-color: #f3f7fb;
  text-align: center;
  position: relative;
  left: 0.5rem;
  padding: 0.7rem;
}

.subscribe .email:focus {
  outline: none;
}

@media only screen and (max-width: 550px) {
  footer {
    .program {
      .sub-title {
        font-size: 1.25rem;
      }
      .desc {
        font-size: 0.9rem;
      }
      .subscribe {
        justify-content: space-between;
        width: 140%;
        scale: 0.8;
        overflow: hidden;
        padding: 0;
        input {
          max-width: 40%;
        }
        button {
          width: fit-content;
        }
      }
    }
  }
  .cr {
    font-size: 0.7rem;
  }
  .links i {
    font-size: 0.8rem;
  }
  #logoFooter {
    height: 2.5rem;
  }
}
@media only screen and (max-width: 450px) {
  .cr {
    font-size: 0.6rem;
  }
  .links i {
    font-size: 0.7rem;
  }
  #logoFooter {
    height: 2.2rem;
  }
}
@media only screen and (max-width: 400px) {
  footer {
    .program {
      .subscribe {
        padding: 0;
      }
    }
  }
  .cr {
    font-size: 0.5rem;
  }
  .links i {
    font-size: 0.6rem;
  }
  #logoFooter {
    height: 2rem;
  }
}
@media only screen and (max-width: 372px) {
  .cr {
    font-size: 0.4rem;
  }
  .links i {
    font-size: 0.5rem;
  }
  #logoFooter {
    height: 1.9rem;
  }
}
@media only screen and (max-width: 333px) {
  .cr {
    font-size: 0.3rem;
  }
  .links i {
    font-size: 0.4rem;
  }
  #logoFooter {
    height: 1.8rem;
  }
}
