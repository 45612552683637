.sponsor-title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 4rem;
  text-align: center;
  margin-bottom: 40px;
}
.sponsors {
  display: flex;
  color: white;
  align-items: center;
  padding: 4rem 2rem;
  flex-direction: column;
  background-color: #0b2345;
}
.cards {
  display: flex;
  gap: 3rem;
  justify-content: center;
  flex-direction: row;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;
  max-width: 17rem;
  margin-bottom: 2rem;
}
.sponsor-sub-title {
  display: flex;
  font-size: 1.3rem;
  font-weight: 1000;
  text-align: center;
  margin-bottom: 20px;
}
.sponsors-sub-title {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 80px;
  padding: 0 1rem;
}
.sponsor-sub-desc {
  display: flex;
  flex-direction: row;
  text-align: justify;
  margin-top: 10px;
}

@media only screen and (max-width: 1286px) {
  .sponsor-title {
    font-size: 2.8rem;
  }
  .sponsors-sub-title {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 1024px) {
  .sponsor-title {
    font-size: 2.6rem;
  }
  .sponsors-sub-title {
    font-size: 1.3rem;
  }
  .sponsor-sub-title {
    font-size: 1.2rem;
  }
}
@media (max-width: 930px) {
  .card {
    max-width: 14rem;
  }
}
@media only screen and (max-width: 768px) {
  .sponsors {
    padding: 2rem 1rem;
  }
  .sponsor-title {
    padding: 0 1rem;
    width: 100%;
    text-align: left;
    font-size: 1.9rem;
    margin-bottom: 1rem;
    margin-top: 1.2rem;
    font-weight: 600;
  }
  .sponsors-sub-title {
    font-size: 1rem;
    text-align: left;
    font-weight: 10;
    margin-bottom: 3rem;
  }
  .cards {
    display: flex;
    flex-direction: column;
  }
  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 80vw;
    gap: 2rem;
  }
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
  }
  .text {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .sponsor-sub-desc {
    font-size: 0.85rem;
    padding: 0;
  }

  .sponsor-sub-title {
    font-size: 1.15rem;
    text-align: center;
  }
}
@media only screen and (max-width: 616px) {
  .sponsor-sub-desc {
    font-size: 0.8rem;
  }

  .sponsor-sub-title {
    font-size: 1.1rem;
    padding: 0;
    margin: 0;
  }
}
@media only screen and (max-width: 480px) {
  .sponsor-sub-title {
    font-size: 0.95rem;
  }
}
@media (max-width: 420px) {
  .sponsor-title {
    font-size: 1.7rem;
  }
  .sponsors-sub-title {
    font-size: 0.9rem;
  }
  #img {
    width: 4rem;
  }
  .sponsor-sub-title {
    font-size: 0.85rem;
  }
  .sponsor-sub-desc {
    font-size: 0.75rem;
  }
  .cards {
    gap: 1.4rem;
  }
}
@media (max-width: 391px) {
}
@media (max-width: 350px) {
  .cards {
    padding: 0 1rem;
  }
  .sponsor-title {
    font-size: 1.4rem;
  }
  .sponsors-sub-title {
    font-size: 0.8rem;
  }

  .sponsor-sub-title {
    font-size: 0.8rem;
  }
  .sponsor-sub-desc {
    font-size: 0.7rem;
  }
}
@media (max-width: 320px) {
  .cards {
    gap: 0.5rem;
  }
  .sponsor-title {
    font-size: 1.2rem;
  }
}
.card img {
  max-width: 10rem;
  object-fit: cover;
}

/* .sub-title {
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 1200px;
  line-height: 2.25rem;
  text-align: center;
} */

.card .sub-desc {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  text-align: justify;
}

@media only screen and (max-width: 768px) {
  .sponsors {
    padding: 4rem 1.5rem;
  }
}
