.Faq {
  width: calc(100% - 0rem);
  height: 50rem;
  background: linear-gradient(176.74deg, #061633 12.1%, #347fc2 118.84%);
  font-weight: 600;
  color: white;
  padding: 2rem 0rem;
}

.faq-title {
  text-align: center;
  font-size: 3rem;
  padding: 4rem 0;
}

.faq-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fa-angle-down.rotate {
  transform: rotate(180deg);
  transform-origin: center;
  /* Animation to make opening smoother */
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: 1;
}

.how_to_register {
  width: 80%;
  font-size: 1.75rem;
  font-weight: 600;
  padding: 1rem 0;
  border-bottom: 0.0625rem solid white;
  margin-bottom: 0.625rem;
  position: relative;
  transition: padding-bottom 0.3s;
}

.Faq i {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  color: white;
}

.hidden-content {
  display: none;
  width: 60%;
  text-align: justify;
}

.show {
  display: block;
  font-size: 1.05rem;
  font-weight: 400;
  padding: 1rem 0;
}

.show .how_to_register {
  padding-bottom: 1.25rem;
}

/* @media only screen and (max-width: 1286px) {}

@media only screen and (max-width: 1024px) {} */

/* @media (max-width: 930px) {} */

@media screen and (max-width: 768px) {
  .Faq {
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    height: 44rem;
  }

  .faq-title {
    font-size: 2rem;
    padding-top: 2.375rem;
    padding-bottom: 1.5rem;
  }

  .how_to_register {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
    font-size: 1rem;
  }

  .hidden-content {
    font-size: 0.8rem;
  }

  .Faq i {
    margin-left: 1.875rem;
    margin-bottom: 1.875rem;
  }
}

/* @media only screen and (max-width: 616px) {} */

@media only screen and (max-width: 480px) {
  .Faq {
    height: 40rem;
  }

  .faq-title {
    font-size: 1.6rem;
  }

  .how_to_register {
    font-size: 0.8rem;
  }

  .hidden-content {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 410px) {
  .Faq {
    padding: 0.5rem;
    height: 35rem;
  }

  .faq-title {
    font-size: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
  }

  .how_to_register {
    margin-left: 4rem;
    margin-right: 4rem;
    font-size: 0.8rem;
    /* width: 16.375rem; */
    padding-top: 0.5rem;
    max-width: 20rem;
  }

  .Faq i {
    margin-left: 40rem;
  }

  .hidden-content {
    font-size: 0.64rem;
  }
}

@media only screen and (max-width: 350px) {
  .faq-title {
    font-size: 1.2rem;
  }

  .how_to_register {
    font-size: 0.7rem;
  }

  .hidden-content {
    font-size: 0.6rem;
  }
}

/* @media (max-width: 350px) {} */

/* @media (max-width: 320px) {} */

/* Animation to smoothly open the faqs */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Animation class to use wherever needed */
.fadeAnimation {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-iteration-count: 1;
}
